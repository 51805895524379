<template>

    <div>
        <div class="llbc">
            <div class="seq dth">회차</div>
            <div class="time dth">시간</div>
            <div class="type dth">종목</div>
            <div class="bet dth">베팅</div>
            <div class="amout dth">베팅금액</div>
            <div class="win dth">당첨금액</div>
            <div class="res dth">결과</div>
        </div>
        <div class="llbc dtd" v-for="(item,index) in latelyBet" :key="index">
            <div class="seq">{{item.leisureGame.sequence}}</div>
            <div class="time">{{item.createTime|datef('DD일 HH:mm')}}</div>
            <div class="type">{{item.attributeConfig.attrName}}</div>
            <div class="bet bbg"> {{item.selectedValueText}}</div>
            <div class="amout">{{item.betCash|comma}}</div>
            <div class="win">{{item.totalCash|comma}}</div>
            <div class="res">
                <font style="color: #fc4646;" v-if="item.betResult === sportsConst.BET_RESULT_LOSE"
                      class="text-red">낙첨</font>
                <font v-if="item.betResult === sportsConst.BET_RESULT_SPECIALCASE" class="text-orange">적특</font>
                <font v-if="item.betResult === sportsConst.BET_RESULT_CANCEL" class="text-red">취소</font>
                <font v-if="item.betResult === sportsConst.BET_RESULT_WIN" class="text-skyblue">당첨</font>
                <font v-if="item.betResult === sportsConst.BET_RESULT_WAITING">대기</font>
            </div>
        </div>

    </div>

</template>

<script>
    import {getlatelyBet} from "../../network/leisureRequest";
    import leisureConst from "../../common/leisureConst";
    import sportsConst from "../../common/sportsConst";

    export default {
        name: "LeisureLatelyBetListComp",
        props: {
            kind: {
                type: Number,
                default() {
                    return 0
                }
            }
        },
        data() {
            return {
                leisureConst,
                sportsConst,
                latelyBet: []
            }
        },
        methods: {
            initLatelyBet() {
                getlatelyBet(this.kind).then(res => {
                    if (res.data.success) {
                        this.latelyBet = res.data.data
                    }
                })
            }
        },
        created() {
            if (this.$store.state.isLogin) {
                this.initLatelyBet()
                this.$bus.$on('leisureBetSuccess', () => {
                    this.initLatelyBet()
                })
            }
        },
    }
</script>

<style scoped>

    .llbc {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        color: #fff9ff;
        border-bottom: 1px solid #4a4a4b;
    }

    .llbc div {
        text-align: center;
    }

    .dth {
        background-color: #030201;
        line-height: 28px;
    }
    .dtd{
        background-color: #272932;
        line-height: 28px;
    }

    .bbg {
        color: gold;
    }

    .seq {
        width: 5%;
        text-align: center;
    }

    .time {
        width: 14.2%;
    }

    .type {
        width: 28.2%;
    }

    .bet {
        width: 14.2%;
    }

    .amout {
        width: 14.2%;
    }

    .win {
        width: 14.2%;
    }

    .res {
        width: 10%;
    }

    @media screen and (max-width: 1024px) {
        .llbc div {
            font-size: 10px;
        }
        .seq {
            width: 10%;
            text-align: center;
        }
        .type {
            width: 23.2%;
        }
    }


</style>