<template>
  <div>
    <div class="bet_cart_section">
      <div class="bet_cart">
        <div class="info_bet">
          <div class="info_box">
            <div class="game01">
              <div class="txt1 ">
                <slot></slot>
              </div>
              <div class="txt2 ">{{selectedInfo.selectText}}</div>
            </div>
            <div class="game01">
              <div class="txt1">보유머니</div>
              <div class="txt2" style="cursor: pointer"  @click="initUserInfo"><i class="fa fa-refresh" style="color: #ffffff;margin-right: 5px"></i>{{$store.state.userInfo.cash|comma}}원</div>
            </div>
            <div class="game01">
              <div class="txt1">배당</div>
              <div class="txt1">당첨금액</div>

            </div>
            <div class="game01">
              <div class="txt2" style="text-align: left;padding-left: 10px;">{{selectedInfo.seletedOdd}}</div>
              <div class="txt2" style="text-align: left;padding-left: 10px;">{{getWinCash|comma}}원</div>

            </div>
          </div>
          <div class="btn_box">
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(10000)">10,000</button>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(50000)">50,000</button>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(100000)">100,000</button>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(300000)">300,000</button>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(500000)">500,000</button>
            <button type="button" class="btn btn_minigame_addcash" @click="cashAdd(1000000)">1,000,000 </button>
            <button type="button" class="btn btn_minigame_otherbtns" @click="allInCash">올인</button>
            <button type="button" class="btn btn_minigame_otherbtns" @click="initBetcash">금액초기화 </button>
          </div>

          <div style="display: flex;justify-content: space-between;align-items: flex-start;padding: 5px;">
            <div style="width: 49%;">
              <input type="text" v-model="betCash" @input="setBetCahsComma" style="width: 100%;border: 1px solid #333232;height: 40px;border-radius: 1px;background-color: #4f4e4e">
            </div>
            <div style="width: 49%;display: flex;flex-direction: column;align-items: center;justify-content: center">
              <button type="button" @click="doBet" style="width: 100%;height:40px;background-color: #020202;border-radius:5px;border: 2px solid #ffffff">배팅하기</button>
<!--              <button type="button" @click="init" style="width: 100%;height:40px;background-color: #c00000;margin-top: 10px">전체초기화</button>-->
            </div>
          </div>

          <div class="bet_money">

          </div>
          <div class="betnow">

          </div>

        </div>
      </div>
    </div>

  </div>


</template>

<script>
  import {doLeisureBet} from "../../network/leisureRequest";
  import {
    RECEIVE_HIDE_LOADING,
    RECEIVE_SHOW_LOADING,
    RECEIVE_SPORTS_CART_ITEM_DEL_ALL
  } from "../../store/mutation-types";
  import {checkLoginMinxin} from "../../common/mixin";

  export default {
    name: "LeisureBetCartComp",
    mixins: [checkLoginMinxin],
    props: {
      kind: {
        type: Number,
        default() {
          return 0
        }
      },
      selectedInfo: {
        selectedAttr: 0,
        selectedGameId: 0,
        selectedWay: 0,
        seletedOdd: 0,
        selectedText: '',
        config: {maxBetCash: 0, minBetCash: 1000, maxWinCash: 0}
      }
    },
    data() {
      return {
        isFirst: true,
        winCash: 0,
        betCash: '',
        betInfo: {attribute: 0, way: 0, gameId: 0, kind: 0, betCash: 0}
      }
    },
    computed: {
      getWinCash() {
        if(this.betCash != ''){
          this.winCash = Math.round(this.getCleanBetCash * this.selectedInfo.seletedOdd);
        } else {
          this.winCash = 0
        }
        return this.winCash

      },
      getCleanBetCash() {
        return parseInt(this.$root.$options.filters.replace(this.betCash, ',', ''))
      },
    },
    methods: {
      /*버튼 올인*/
      allInCash() {
        this.betCash = this.$root.$options.filters.comma(this.$store.state.userInfo.cash)
      },
      /*버튼 초기화*/
      initBetcash() {
        this.betCash = ''
      },
      /*버튼 캐쉬클릭 ++*/
      cashAdd(amount) {
        if (this.betCash == '') {
          this.betCash = 0;
        }
        this.betCash = this.$root.$options.filters.comma(this.getCleanBetCash + amount);
      },
      setBetCahsComma(){
        this.betCash = this.$root.$options.filters.comma(this.betCash)
      },

      /*베팅하기*/
      doBet() {
        if (!this.checkLogin()) {
          return false
        }
        this.$swal({
          title: this.$root.$options.filters.comma(this.betCash) + '을 베팅하시겠습니까?',
          type: 'question',
          confirmButtonText: ' 예 ',
          cancelButtonText: '아니오'
        }).then((res) => {
          this.betInfo = {selectedVals: []}
          if (res.value) {
            if (this.selectedInfo.selectedGameId === 0
                    || this.selectedInfo.selectedAttr === 0
                    || this.selectedInfo.selectedWay === 0
                    || this.selectedInfo.seletedOdd === 0) {
              this.$swal({
                title: '베팅하실 경기를 선택하세요',
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
              return false
            }

            this.betInfo.kind = this.kind
            this.betInfo.attribute = this.selectedInfo.selectedAttr
            this.betInfo.way = this.selectedInfo.selectedWay
            this.betInfo.gameId = this.selectedInfo.selectedGameId
            this.betInfo.betCash = this.getCleanBetCash
            doLeisureBet(this.betInfo).then(res => {
              if (res.data.success) {
                this.$swal({
                  title: '베팅성공',
                  type: 'success',
                  showCancelButton: false,
                  showConfirmButton: true
                })
                this.betCash = ''
                //베팅성공시 회원정보를 init
                //UserInfoComp.vue에서 모니터
                this.$store.dispatch('actionUserInfo')
                //선택초기화
                this.$bus.$emit('leisureBetSuccess')
              } else {
                this.$swal({
                  title: res.data.msg,
                  type: 'error',
                  showCancelButton: false,
                  showConfirmButton: true
                })
                // setTimeout(() => {
                //   this.$router.go(0)
                // }, 1500)
              }
            })
          }
        })
      },
      init(){
        this.initBetcash()
        this.$bus.$emit('leisureBetSuccess')
      },
      initUserInfo(){
        this.$store.commit(RECEIVE_SHOW_LOADING)
        this.$bus.$emit('leisureBetSuccess')
        this.$emit('refreshTimer');
        this.$store.dispatch('actionUserInfo')
        setTimeout(()=>{
          this.$store.commit(RECEIVE_HIDE_LOADING)
        },1000)
      }
    },
    created() {

    },
    watch: {
      "selectedInfo": {
        deep: true,
        handler: function (newValue) {
          //console.log(newValue)
        }
      },
    }
  }
</script>

<style scoped>

</style>